<template>
  <div class="container-auctionhousedetails">
    <div class="auctionhousedetails-header">
      <div class="auctionhousedetails-header-left">
        <!-- <img :src="auctionhousedetailsData.image" alt=""> -->
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <img :src="auctionhousedetailsData.image" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="auctionhousedetails-header-right">
        <div class="right-text-collt">
          <span>{{auctionhousedetailsData.title}}</span>
          <a class="right-collot" @click="biddingCollot()">
            <i class="iconfont " :class="[auctionhousedetailsData.status ===0?'icon-kongaixin':' icon-aixin']"
              style="color:rgb(234, 49, 74)"></i>
            <a href="javascript:;" class="click-collot">收藏</a>
          </a>
        </div>
        <!-- <p>{{auctionhousedetailsData.address}}</p> -->
        <p v-html="auctionhousedetailsData.description"></p>
      </div>
    </div>
    <div class="auctionhousedetails-middle">
      <span>即将开拍</span>
      <p class="font-s">共<span>{{auctionhousedetailsData.count}}</span>场拍卖会</p>
    </div>
    <div class="auctionhousedetails-bottom">
      <Auctionhouselist :auctionhouselistProps='auctionhousedetailsDataList' @collectAuction='collectAuction' />
      <login ref="login" />
    </div>
    <div class="auctionhousedetails-middle">
      <span>历史记录</span>
      <p class="font-s">共<span>{{auctionhousedetailsData.endCount}}</span>场拍卖会</p>
    </div>
    <div class="auctionhousedetails-bottom">
      <HistoricalRecord :HistoricalRecordData='HistoricalRecordData' :endCount="endCount"
        @collectAuction='collectAuction' />
      <login ref="login" />
    </div>
  </div>
</template>
<script>
  import Auctionhouselist from './components/Auctionhouselist.vue'
  import {
    auctionHouseInfoList,
    collectAuctionHouse
  } from '../../api/index'
  import HistoricalRecord from './components/Historicalrecord.vue'
  import login from '../../components/login.vue'
  export default {
    name: 'Auctionhousedetails',
    components: {
      Auctionhouselist,
      login,
      HistoricalRecord
    },
    data() {
      return {
        auctionhousedetailsData: {},
        auctionhousedetailsDataList: [],
        list: [],
        HistoricalRecordData: [],
        endCount: 0,
        page: 1,
        pageSize: 10
      }
    },
    created() {
      sessionStorage.setItem('msgInfo10', true)
      this.auctionHouseInfoList()
      this.setTimeCount()
      this.setTimeCountTwo()
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      async auctionHouseInfoList() {
        const res = await auctionHouseInfoList({
          id: Number(this.$route.query.auctionhousedetailsid),
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          page: this.page,
          pageSize: this.pageSize
        })
        if (res.code === 0) {
          this.auctionhousedetailsData = res.data
          this.auctionhousedetailsDataList = res.data.list
          this.endCount = res.data.endCount
          this.HistoricalRecordData = res.data.endList
          this.list = res.data.list
          // for (const i in this.list) {
          //   this.list[i].countDownTime = ''
          //   this.countDown(i)
          // }
        } else {
          this.$message.error(res.msg)
        }
      },
      async biddingCollot() {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await collectAuctionHouse({
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auctionHouseId: Number(this.$route.query.auctionhousedetailsid)
        })
        if (res.code === 0) {
          if (res.data.status === true) {
            this.$message.success('收藏成功')
          } else {
            this.$message.error('取消成功')
          }
          this.auctionHouseInfoList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 收藏后刷新
      collectAuction(val) {
        this.page = val
        this.auctionHouseInfoList()
      },
      setTimeCount() {
        const Y = new Date().getFullYear()
        const M = new Date().getMonth() + 1 - 0 >= 10 ? Number(new Date().getMonth()) + 1 : '0' + (Number(new Date()
          .getMonth()) + 1)
        const D = new Date().getDate() >= 10 ? new Date().getDate() : '0' + new Date().getDate()
        const h = new Date().getHours() >= 10 ? new Date().getHours() : '0' + new Date().getHours()
        const m = new Date().getMinutes() >= 10 ? new Date().getMinutes() : '0' + new Date().getMinutes()
        const s = new Date().getSeconds() >= 10 ? new Date().getSeconds() : '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        setInterval(() => {
          for (let j = 0; j < this.list.length; j++) {
            const now = Math.round(new Date().getTime())
            this.list[j].endTime = this.list[j].endTime.replace(/-/g, "/")
            let tmpProducts = []
            tmpProducts = this.list
            if (this.list[j].endTime !== null && date2 <= this.list[j].endTime) {
              this.$set(this.list[j], 'overtime', Date.parse(this.list[j].endTime) - now)
              this.$set(this.list[j], 'overD', Math.floor(Math.floor((Date.parse(tmpProducts[j].endTime) - now) /
                1000 / 60 / 60 / 24)))
              this.$set(this.list[j], 'overH', Math.floor((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60 /
                60 % 24))
              this.$set(this.list[j], 'overM', Math.floor((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60 %
                60))
              this.$set(this.list[j], 'overS', Math.floor((Date.parse(tmpProducts[j].endTime) - now) / 1000 % 60))
            }
          }
        }, 1000)
      },
      setTimeCountTwo() {
        const Y = new Date().getFullYear()
        const M = new Date().getMonth() + 1 - 0 >= 10 ? Number(new Date().getMonth()) + 1 : '0' + (Number(new Date()
          .getMonth()) + 1)
        const D = new Date().getDate() >= 10 ? new Date().getDate() : '0' + new Date().getDate()
        const h = new Date().getHours() >= 10 ? new Date().getHours() : '0' + new Date().getHours()
        const m = new Date().getMinutes() >= 10 ? new Date().getMinutes() : '0' + new Date().getMinutes()
        const s = new Date().getSeconds() >= 10 ? new Date().getSeconds() : '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        setInterval(() => {
          for (let j = 0; j < this.list.length; j++) {
            const now = Math.round(new Date().getTime())
            this.list[j].startTime = this.list[j].startTime.replace(/-/g, "/")
            let tmpProducts = []
            tmpProducts = this.list
            if (this.list[j].startTime !== null && date2 <= this.list[j].startTime) {
              this.$set(this.list[j], 'overtime1', Date.parse(this.list[j].startTime) - now)
              this.$set(this.list[j], 'overD1', Math.floor(Math.floor((Date.parse(tmpProducts[j].startTime) - now) /
                1000 / 60 / 60 / 24)))
              this.$set(this.list[j], 'overH1', Math.floor((Date.parse(tmpProducts[j].startTime) - now) / 1000 /
                60 / 60 % 24))
              this.$set(this.list[j], 'overM1', Math.floor((Date.parse(tmpProducts[j].startTime) - now) / 1000 /
                60 % 60))
              this.$set(this.list[j], 'overS1', Math.floor((Date.parse(tmpProducts[j].startTime) - now) / 1000 %
                60))
            }
            // console.log(this.list)
          }
        }, 1000)
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-auctionhousedetails {
    margin: 0 90px;

    .auctionhousedetails-header {
      margin-top: 40px;
      margin-bottom: 50px;
      display: flex;

      .auctionhousedetails-header-left {
        // img {
        //   width: 600px;
        //   height: 360px;
        // }
        width: 400px;

        .col-md-3 {
          width: 100%;
          margin: 0;
          border: none;
          height: none;

          .thumb-container {
            width: 100%;
            height: 100px;
            position: relative;
            // padding-bottom: 100%;
            margin: 0;
            border: none;

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }

      .auctionhousedetails-header-right {
        margin-left: 105px;
        text-align: left;

        .right-text-collt {
          display: flex;
          align-items: center;

          span {
            font-size: 36px;
            font-weight: 400;
            color: #333;
          }

          .right-collot {
            width: 80px;
            height: 24px;
            border: 1px solid #cda156;
            opacity: 0.7;
            border-radius: 4px;
            background: #ffffff;
            margin: 0 0 0 20px;
            display: inline-block;
            line-height: 21px;
            text-align: center;
            color: #cda156;

            &:hover {
              box-shadow: none;
              text-decoration: none;
            }

            i {
              font-size: 14px;
            }

            .click-collot {
              font-size: 14px;
              color: #cda156;
              padding-bottom: 0;
              padding-left: 4px;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        p:nth-child(2) {
          width: 915px;
          margin-top: 30px;
          font-size: 18px;
          font-weight: 400;
          color: #333;
          height: 200px;
          overflow: hidden;

          &:hover {
            // height: 200px;
            overflow-y: scroll;
          }
        }

        p:nth-child(3) {
          width: 915px;
          margin-top: 30px;
          font-size: 18px;
          font-weight: 400;
          color: #333;
        }
      }
    }

    .auctionhousedetails-middle {
      font-size: 36px;
      font-weight: 400;
      color: #333;
      padding-bottom: 20px;
      border-bottom: 1px solid #dedede;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .font-s {
        font-size: 20px;
        margin-bottom: 0;

        span {
          color: #cda156;
        }
      }
    }
  }
</style>
