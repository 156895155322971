<template>
  <div class="container-bidding">
    <div class="bidding-main" v-if="HistoricalRecordData !== null">
      <div v-for="(item,index) in HistoricalRecordData" :key="index">
        <!-- <div class="bidding-main-left" >
          <img :src="item.image" alt="">
        </div> -->
        <!-- <div :style="{background:'url('+item.image+')' } " @click="auctionDetails(item.id)" class="forBackgroundImage">
        </div> -->
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <a href="javascript:;" @click="auctionDetails(item.id)">
                <img :src="item.image+imgSize(1314,951)" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="bidding-main-right">
          <div class="bidding-main-right-left">

            <div class="bidding-collot">
              <router-link target="_blank" :to="{path:'/auctiondetails',query:{auctiondetailsId:item.id}}">
                {{item.chineseTitleAuction}}
              </router-link>
              <!-- <a href="javascript:;" @click="auctionDetails(item.id)" :title="item.chineseTitleAuction">
                {{item.chineseTitleAuction}}
              </a> -->
              <a class="right-collot" @click="biddingCollot(item.id)">
                <i class="iconfont " :class="[item.status ===1?'icon-aixin':'icon-kongaixin']"
                  style="color:rgb(234, 49, 74);font-size:13px"></i>
                <a href="javascript:;" class="click-collot">收藏</a>
              </a>
              <a class="right-collot" @click="startBidding(item.id)"
                v-if="item.overD !== undefined && hide && item.auctionWay === 8">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:rgb(234, 49, 74);font-size:15px;"></i>
                <a href="javascript:;" class="click-collot">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a>
              <a class="right-collot" @click="startBidding(item.id)"
                v-if="item.overD1 !== undefined && hide && item.auctionWay === 7">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:rgb(234, 49, 74);font-size:15px;"></i>
                <a href="javascript:;" class="click-collot">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a>
              <!-- <a class="right-collot" v-else style="border:1px solid #999">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:#999;font-size:15px;"></i>
                <a href="javascript:;" class="click-collot"
                  style="color:#999;">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a> -->
            </div>
            <a href="javascript:;" :title="item.auctionHouseTitle"
              @click="auctionhousedetails(item.auctionHouseId)">{{item.auctionHouseTitle}}</a>
            <p>
              <span>{{item.startTime}}</span>
              <span>|</span>
              <span>北京时间</span>
            </p>
            <p class="yellowCad">
              <span>保证金 {{item.earnestMoney}}</span>
              <span style="padding:0 10px">|</span>
              <span v-if="item.auctionWay === 7" style="color:#CD5656;">直播专场</span>
              <span v-else-if="item.auctionWay === 8" style="color:#cda156;">代拍专场</span>
            </p>
            <p class="addr">
              <img src="../../../assets/images/12.png" alt="">
              <span>{{item.location}}</span>
            </p>
          </div>
          <div class="right-browse">

            <router-link target="_blank" class="bidding-main-right-right" v-if="item.auctionStatus === 4" to=""
              style="background: #DEDEDE;">
              已结束
            </router-link>

          </div>
        </div>
      </div>
    </div>
    <div class="estate-auction" v-else>
      <img src="../../../assets/images/22.png" alt="">
      <span>暂无数据</span>
    </div>
    <a-pagination size="small" :total="endCount" :pageSize="10" @change="onChange" />
    <login ref='login' />
  </div>
</template>
<script>
  import {
    emindShooting,
    collectAuction
  } from '../../../api/index'
  import login from '../../../components/login.vue'
  export default {
    name: 'Auctionhouselist',
    props: {
      HistoricalRecordData: {
        type: Array
      },
      endCount: {
        type: Number
      }
    },
    components: {
      login
    },
    data() {
      return {
        hide: true
      }
    },
    created() {
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
    },
    methods: {
      onChange(page) {
        this.$emit('collectAuction', page)
      },
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      // 跳转拍卖会详情
      auctionDetails(id) {
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: id
          }
        })
      },
      async biddingCollot(id) {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await collectAuction({
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auctionId: id
        })
        if (res.code === 0) {
          if (res.data.status === true) {
            this.$message.success('收藏成功')
          } else {
            this.$message.error('取消成功')
          }
          this.$emit('collectAuction')
        } else {
          this.$message.error(res.msg)
        }
      },
      // 开拍提醒
      async startBidding(auctionHouseId) {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await emindShooting({
          userId: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auctionId: Number(auctionHouseId)
        })
        if (res.code === 0) {
          this.$message.success(res.data.status?'系统将在拍卖会开始前1小时提醒您及时参与竞拍':'取消成功')
          this.$emit('collectAuction')
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖行详情
      auctionhousedetails(id) {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      }

    }
  }
</script>
<style lang="less" scoped>
  .container-bidding {
    /deep/ .ant-pagination-item-active {
      border: none;
    }

    #components-pagination-demo-mini .ant-pagination:not(:last-child) {
      margin-bottom: 24px;
    }

    /deep/ .ant-select {
      width: 80px !important;
    }

    /deep/ .ant-pagination-item-active a {
      color: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #cda156;
    }

    /deep/ .ant-pagination-item {
      font-size: 17px;
    }

    /deep/ .ant-pagination-item-active a {
      color: #fff;
      background: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #000;
    }

    /deep/ .ant-pagination-item {
      border: 1px solid #ccc !important;
      margin: 0px 5px;

      a {
        font-size: 15px;
      }
    }

    /deep/ .ant-pagination-prev {
      border: 1px solid #ccc !important;
      margin-right: 5px;
    }

    /deep/ .ant-pagination-next {
      border: 1px solid #ccc !important;
      margin-left: 5px;
    }

    /deep/ .anticon {
      vertical-align: 0;
    }

    /deep/ .ant-pagination-item-active {
      background: #cda156;
    }

    width: 1725px;
    padding-bottom: 20px;

    .bidding-main {
      // width: 100%;

      div:hover {
        box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.2);
      }

      div {
        // width: 100%;
        margin: 20px 0px;
        // height: 360px;
        border: 1px solid #979797;
        // box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.1);
        display: flex;

        .forBackgroundImage {
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          // width: 100%;
          // z-index: -1;
          width: 452px;
          margin: 0;
          opacity: 1;
          // height: 100%;
          border-top: none;
          border-left: none;
        }

        .col-md-3 {
          width: 452px;
          margin: 0;
          border: none;
          height: none;

          &:hover {
            box-shadow: none;
          }

          .thumb-container {
            width: 100%;
            height: 317px;
            position: relative;
            // padding-bottom: 100%;
            margin: 0;
            border: none;

            &:hover {
              box-shadow: none;
            }

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              &:hover {
                box-shadow: none;
              }

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }

        .bidding-main-left {
          width: 450px;
          height: 100%;
          // border: 1px dashed #979797;
          margin: 0;
          box-shadow: none;
          // img {
          //   height: 100%;
          // }
        }

        p {
          margin-bottom: 0;
        }

        .bidding-main-right {
          width: 1320px;
          // height: 100%;
          margin: 0;
          border: none;
          box-sizing: border-box;
          padding: 0px 30px 0 30px;
          justify-content: space-between;

          &:hover {
            box-shadow: none;
          }

          .bidding-main-right-left {
            margin: 0;
            border: none;
            box-shadow: none;
            flex-direction: column;
            text-align: left;

            &:hover {
              box-shadow: none;
            }

            .bidding-collot {
              margin: 0;
              border: none;
              padding-bottom: 20px;
              align-items: center;

              &:hover {
                box-shadow: none;
              }

              a:nth-child(1) {
                // margin-top: 40px;
                // width: 900px;
                font-size: 24px;
                font-weight: 500;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;

                &:hover {
                  text-decoration: underline;
                }
              }

              .right-collot {
                // width: 80px;
                height: 24px;
                border: 1px solid #cda156;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: block;
                line-height: 21px;
                text-align: center;
                color: #cda156;
                margin-top: 40px;
                padding: 0 9px;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot {
                  font-size: 14px;
                  color: #cda156;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

            p {
              margin-top: 20px;
            }

            a:nth-child(1) {
              margin-top: 40px;
              font-size: 24px;
              font-weight: 500;
              color: #333;

              &:hover {
                text-decoration: underline;
              }
            }

            .yellowCad {
              font-size: 20px;
              font-weight: 400;
              // color: #cda156 !important;
            }

            .addr {
              display: flex;
              align-items: center;
            }

            p:nth-child(3) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              span:nth-child(2) {
                padding: 0 10px;
              }
            }

            p:nth-child(4) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
            }

            p:nth-child(5) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              img {
                width: 14px;
                height: 20px;
                margin-right: 10px;
              }
            }
          }

          .right-browse {
            margin: 0;
            border: none;
            flex-direction: column;
            align-items: center;

            .cut-off {
              color: #666;
              font-size: 20px;
              padding-top: 20px;
            }

            .pd-off {
              padding-top: 4px;
            }

            &:hover {
              box-shadow: none;
            }

            .bidding-main-right-right {
              display: flex;
              margin: 0;
              border: none;
              box-shadow: none;
              width: 280px;
              height: 44px;
              background: #cda156;
              border-radius: 8px;
              opacity: 0.7;
              color: #6e5121;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              margin-top: 40px;
            }

            .start-bidding {
              padding-top: 20px;
              font-size: 18px;
              color: #cda156;
            }
          }
        }
      }
    }

    .bidding-all {
      margin-top: 37px;
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 12px;
        height: 16px;
      }

      a {
        color: #cda156;
        font-size: 24px;
        margin-right: 10px;
      }
    }

    .estate-auction {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;

      img {
        width: 234px;
      }

      span {
        padding-top: 30px;
        color: #cda156;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
</style>
